import {
  Autocomplete,
  Box,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectReseller } from '../../../common/store/reseller';
import {
  ManualOrderEntryApiResponse,
  manualOrderStatusFieldNames,
  manualOrderStatusFilterValueOptions
} from '../manual-orders';
import { useUpdateManualOrdersMutation } from '../manual-orders-api-slice';
import {
  selectSelectedManualOrders,
  setIsSubmittingManualOrders
} from '../manual-orders-slice';
import { manualOrdersTabs } from '../manual-orders-tabs';
import { ManualOrderStatusCompleteConfirmDialog } from './ManualOrderStatusCompleteConfirmDialog';
import { ManualOrderStatusSnackbar } from './ManualOrderStatusSnackbar';

type ManualOrdersStatusAutocompleteProps = {
  selectedContainsCompleted: boolean;
};

export const ManualOrderStatusAutocomplete = ({
  selectedContainsCompleted
}: ManualOrdersStatusAutocompleteProps) => {
  const selectedManualOrders = useSelector(selectSelectedManualOrders);
  const reseller = useSelector(selectReseller);

  const dispatch = useDispatch();

  const [completeConfirmationDialogOpen, setCompleteConfirmationDialogOpen] =
    useState(false);

  const [newStatus, setNewStatus] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [displayedSelectedStatus, setDisplayedSelectedStatus] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [updateManualOrders, { isError, isSuccess, reset }] =
    useUpdateManualOrdersMutation();

  // set displayed autocomplete value one selected change
  // eslint-disable-next-line complexity
  useEffect(() => {
    const moreThanOneSelected = (selectedManualOrders?.length ?? 0) > 1;
    const oneSelected = (selectedManualOrders?.length ?? 0) === 1;

    if (moreThanOneSelected) {
      const statusToCompare = selectedManualOrders![0].$status;
      const allStatusesTheSame = !selectedManualOrders!.some(
        (manualOrder: ManualOrderEntryApiResponse) =>
          manualOrder.status !== statusToCompare.value
      );

      if (!allStatusesTheSame && displayedSelectedStatus !== null) {
        setDisplayedSelectedStatus(null);
      } else if (allStatusesTheSame && displayedSelectedStatus === null) {
        setDisplayedSelectedStatus(statusToCompare);
      }
    } else if (oneSelected) {
      const displayedStatusDiffers =
        selectedManualOrders![0].$status !== displayedSelectedStatus;

      if (displayedStatusDiffers) {
        setDisplayedSelectedStatus(selectedManualOrders![0].$status);
      }
    } else if (displayedSelectedStatus !== null) {
      setDisplayedSelectedStatus(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedManualOrders]);

  const getStatusTooltipTitle = () => {
    if (selectedContainsCompleted) {
      return 'One or more selected manual orders are complete and their status cannot be updated.';
    } else {
      return null;
    }
  };

  const getOptionDisabled = (option: { label: string; value: string }) => {
    const oneOrMoreOrdersMissingCompleted = selectedManualOrders!.some(
      (manualOrder: ManualOrderEntryApiResponse) =>
        manualOrder.completedDate === undefined ||
        manualOrder.completedDate === null
    );

    return option.label === 'Complete' && oneOrMoreOrdersMissingCompleted;
  };

  const getCompletionProhibitedTitle = (key: string) => {
    if (getOptionDisabled({ label: key, value: key })) {
      return 'Cannot change status to complete until all selected orders have had their completion date set.';
    } else {
      return null;
    }
  };

  const submitManualOrderStatusChange = () => {
    return updateManualOrders({
      manualOrders: selectedManualOrders!.map(
        (manualOrder: ManualOrderEntryApiResponse) => ({
          ...manualOrder,
          status: newStatus?.value
        })
      ),
      reseller: reseller
    });
  };

  const handleStatusChange = (
    _event: unknown,
    value: { label: string; value: string } | null
  ) => {
    dispatch(setIsSubmittingManualOrders(true));

    setNewStatus(value);

    if (value?.value === 'COMPLETE') {
      setCompleteConfirmationDialogOpen(true);
    } else {
      updateManualOrders({
        manualOrders: selectedManualOrders!.map(
          (manualOrder: ManualOrderEntryApiResponse) => ({
            ...manualOrder,
            status: value?.value
          })
        ),
        reseller: reseller
      });
    }
  };

  const resetFormAndCache = () => {
    setNewStatus(null);

    reset();
  };

  const cancelConfirmationDialog = () => {
    setNewStatus(null);
    setCompleteConfirmationDialogOpen(false);

    dispatch(setIsSubmittingManualOrders(false));
  };

  return (
    <>
      <ManualOrderStatusSnackbar
        fieldName={manualOrderStatusFieldNames.status}
        isError={isError}
        isSuccess={isSuccess}
        reset={resetFormAndCache}
      />
      <ManualOrderStatusCompleteConfirmDialog
        cancelDialog={cancelConfirmationDialog}
        // @ts-expect-error - This function returns more than just a plain Promise<never>, but we don't care here
        handleSubmit={submitManualOrderStatusChange}
        isOpen={completeConfirmationDialogOpen}
      />
      <Tooltip arrow followCursor title={getStatusTooltipTitle()}>
        <Autocomplete
          disabled={selectedContainsCompleted}
          fullWidth
          getOptionDisabled={getOptionDisabled}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={handleStatusChange}
          options={manualOrderStatusFilterValueOptions}
          renderInput={params => (
            <TextField label={manualOrdersTabs.STATUS.label} {...params} />
          )}
          renderOption={params => (
            <Tooltip
              arrow
              followCursor
              key={params.key}
              title={getCompletionProhibitedTitle(params.key)}
            >
              <Box>
                <Typography {...params}>{params.key}</Typography>
              </Box>
            </Tooltip>
          )}
          size="small"
          value={displayedSelectedStatus}
        />
      </Tooltip>
    </>
  );
};
